var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "el-icon-user", nombrePagina: "Permisos: Pantallas" }
      }),
      _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Nombre", prop: "pantalla" }
                }),
                _c("el-table-column", {
                  attrs: { label: "Operaciones", width: "110px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                round: "",
                                plain: "",
                                disabled: !props.row.habilitado
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/roles/" +
                                      _vm.$route.params.id +
                                      "/pantallas/" +
                                      props.row.pantallaID +
                                      "/permisos/"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-finished" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Visible", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            on: {
                              change: function($event) {
                                return _vm.toogleHabilitar(props.row)
                              }
                            },
                            model: {
                              value: props.row.habilitado,
                              callback: function($$v) {
                                _vm.$set(props.row, "habilitado", $$v)
                              },
                              expression: "props.row.habilitado"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }