<template>
  <div>
    <maca-titulo-pagina
      icono="el-icon-user"
      nombrePagina="Permisos: Pantallas"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '25px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Nombre" prop="pantalla"></el-table-column>
          <el-table-column label="Operaciones" width="110px">
            <template slot-scope="props">
              <el-button
                type="primary"
                size="small"
                round
                plain
                @click="
                  $router.push(
                    '/roles/' +
                      $route.params.id +
                      '/pantallas/' +
                      props.row.pantallaID +
                      '/permisos/'
                  )
                "
                :disabled="!props.row.habilitado"
              >
                <i class="el-icon-finished"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Visible" width="100px">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.habilitado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleHabilitar(props.row)"
              ></el-switch>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "permisos-usuario",
  data() {
    return {
      urlTabla: "/permiso/obtenerTodosRol",
      paramsTabla: {
        rolID: this.$route.params.id,
        tipo: "V",
      },
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
    };
  },
  methods: {
    async toogleHabilitar(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: this.$route.params.id, permisoID: fila.id };
      let respuestaApi = null;

      if (fila.habilitado) {
        respuestaApi = await this.$maca.api.post("/rol/crearPermiso", params);
      } else {
        respuestaApi = await this.$maca.api.post(
          "/rol/eliminarPermiso",
          params
        );
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
};
</script>

<style></style>
